import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InstaGrid from "../components/instagramGrid"

import "../styles/index.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="fourOfour">
      <h1>Oops, No Page Found :( </h1>
      <p>You just hit a route that doesn&#39;t exist... no hacking now!.</p>
      <p>
        Go back to the <a href="http://healthhitters.com">Homepage</a> and try again!
      </p>
    </div>
    <InstaGrid />
  </Layout>
)

export default NotFoundPage
